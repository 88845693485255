<template>
  <!-- DASHBOARD COUNSELING FOR USER PUBLIC -->
  <div class="_bg-default _80w _100vh px-5 py-3">
    <div class="_100top">
      <v-tabs
        v-model="tab"
        class="radius-card mb-5"
        hide-slider
        style="overflow: hidden"
        grow
      >
        <v-tab href="#tab-1" active-class="active_tabs"
          ><v-icon class="mr-2">mdi-comment</v-icon><b>Semua Sesi</b></v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tab" style="background: none">
        <v-tab-item value="tab-1">
          <on-going />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import onGoing from "../../../../components/Counseling/Public/onGoing.vue";
export default {
  components: { onGoing },
  name: "counselingPublicdash",
  data() {
    return {
      tab: null
    };
  }
};
</script>

<style scoped>
.active_tabs {
  background: #3b053c !important;
  color: #fff !important;
}
</style>
