<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <v-card
      v-if="loading"
      min-height="300px"
      class="radius-card d-flex align-center justify-center"
    >
      <v-progress-circular
        indeterminate
        color="purple"
        class="mr-3"
      ></v-progress-circular>
      <b>Loading</b>
    </v-card>
    <v-card min-height="300px" class="radius-card" v-if="trx && !loading">
      <div
        v-if="!trx.length"
        class="d-flex pa-2 justify-center align-center"
        style="height: 300px"
      >
        <div>
          <h3 class="color_txt">Tidak ada sesi yang sedang berjalan</h3>
          <p>Cari Psikolog untuk memulai sesi Anda</p>
          <v-btn color="#3B053C" dark to="/counseling/find-psycolog">
            <v-icon class="mr-2">mdi-magnify</v-icon>
            Cari psikolog
          </v-btn>
        </div>
      </div>
      <div class="grey lighten-4" style="min-height: 300px" v-else>
        <!-- <v-card class="pa-3 rounded-lg"> </v-card> -->

        <div class="pa-3">
          <div class="d-flex justify-end my-3">
            <v-btn color="#3B053C" dark to="/counseling/find-psycolog"
              >Tambah Sesi Baru</v-btn
            >
          </div>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item, i) in trx"
              :key="`sect_${i}`"
              elevation="1"
              class="rounded-lg mb-2"
            >
              <v-expansion-panel-header class="pa-3">
                <v-row>
                  <v-col cols="6">
                    <div class="d-flex align-center" v-if="item.psycholog">
                      <v-avatar size="40" class="mr-2">
                        <img
                          :src="
                            item.psycholog.photo_profile
                              ? `${env}/upload/photo_profile/${item.psycholog.id}/${item.psycholog.photo_profile}`
                              : ava
                          "
                          class="obj_fit"
                          alt="isyan"
                        />
                      </v-avatar>
                      <div>
                        <h3>
                          {{ item.psycholog.nama_lengkap }}
                        </h3>
                        <p class="mb-0 small_txt">{{ item.id }}</p>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="d-flex justify-end mx-3">
                      <v-chip
                        v-if="item.status == 0"
                        color="yellow"
                        text-color="white"
                      >
                        Pending
                      </v-chip>
                      <v-chip
                        v-if="item.status == 1"
                        color="green"
                        text-color="white"
                      >
                        Approved
                      </v-chip>
                      <v-chip
                        v-if="item.status == 2"
                        color="red"
                        text-color="white"
                      >
                        Rejected
                      </v-chip>
                      <v-chip
                        v-if="item.status == 3"
                        color="red"
                        text-color="white"
                      >
                        EXPIRED
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex" v-if="item.status == 1">
                  <div style="width: 35px"></div>
                  <div style="flex: 1">
                    <div class="mb-2">
                      <b class="mb-3">Tanggal dan Waktu Konsultasi (WIB)</b>
                      <v-card
                        outlined
                        v-for="(sesi, j) in item.sessions"
                        :key="`sesi-${j}`"
                        class="mb-2 pa-2"
                      >
                        <v-row>
                          <v-col cols="6">
                            <div class="d-flex">
                              <p class="mb-0 mr-2">
                                Sesi {{ sesi.session }} ({{
                                  sesi.schedule.duration
                                }}
                                menit)
                              </p>
                              <v-icon
                                color="green"
                                size="20"
                                v-if="sesi.schedule.is_done == 1"
                                >mdi-check-circle</v-icon
                              >
                            </div>
                            <div v-if="sesi.schedule.is_done == 1">
                              <div class="d-flex">
                                <v-card class="pa-2 mr-3" outlined>
                                  <v-icon color="#3b053c">mdi-clock</v-icon>
                                  <v-icon color="orange">mdi-star</v-icon>
                                  {{ sesi.schedule.rating_waktu }}
                                </v-card>
                                <v-card class="pa-2 mr-3" outlined>
                                  <v-icon color="#3b053c">mdi-head-cog</v-icon>
                                  <v-icon color="orange">mdi-star</v-icon>
                                  {{ sesi.schedule.rating_kualitas }}
                                </v-card>
                                <v-card class="pa-2 mr-3" outlined>
                                  <v-icon color="#3b053c">mdi-thumb-up</v-icon>
                                  <v-icon color="orange">mdi-star</v-icon>
                                  {{ sesi.schedule.rating }}
                                </v-card>
                              </div>
                              <p class="mb-0 small_txt">
                                reflection : <br />
                                {{ sesi.schedule.reflection_desc }}
                              </p>
                              <div
                                v-if="
                                  !sesi.schedule.rating ||
                                    !sesi.schedule.rating_waktu ||
                                    !sesi.schedule.rating_kualitas
                                "
                              >
                                <p
                                  class="mb-0 small_txt grey--text"
                                  v-if="sesi.schedule.is_done == 1"
                                >
                                  <v-btn
                                    class="mt-2"
                                    color="#3b053c"
                                    depressed
                                    :to="`/done-counseling/${item.id}`"
                                    dark
                                    >Beri Penilaian</v-btn
                                  >
                                </p>
                              </div>
                            </div>
                            <div v-if="sesi.schedule.is_done == 0">
                              <b v-if="sesi.schedule.booking_time">
                                {{
                                  $date(sesi.schedule.booking_time).format(
                                    "dddd, DD MMMM YYYY",
                                  )
                                }}
                                <br />
                                {{
                                  $date(sesi.schedule.booking_time).format(
                                    "HH:mm",
                                  )
                                }}
                                (WIB)
                              </b>
                              <p
                                class="mb-0 small_txt grey--text"
                                v-if="
                                  j !== 0 &&
                                    item.sessions[j - 1].schedule.is_done == 0
                                "
                              >
                                Belum ada jadwal.
                              </p>

                              <v-btn
                                v-if="
                                  j !== 0 &&
                                    item.sessions[j - 1].schedule.is_done ==
                                      1 &&
                                    !sesi.schedule.booking_time
                                "
                                class=" mt-2"
                                @click="
                                  setSchedule({
                                    data: item,
                                    sesi: sesi,
                                    index: j,
                                    type: 'set',
                                  })
                                "
                                color="#3b053c"
                                dark
                                depressed
                                >Atur Jadwal</v-btn
                              >
                              <v-btn
                                v-if="j == 0 && !sesi.schedule.booking_time"
                                class=" mt-2"
                                @click="
                                  setSchedule({
                                    data: item,
                                    sesi: sesi,
                                    index: j,
                                    type: 'set',
                                  })
                                "
                                color="#3b053c"
                                dark
                                depressed
                                >Atur Jadwal</v-btn
                              >
                            </div>
                          </v-col>
                          <v-col cols="6" v-if="item.status == 1">
                            <div class="d-flex justify-end">
                              <div v-if="sesi.schedule.is_done == 0">
                                <v-btn
                                  v-if="sesi.schedule.reschedule_count == 0"
                                  :disabled="!sesi.schedule.booking_time"
                                  @click="
                                    setSchedule({
                                      data: item,
                                      sesi: sesi,
                                      index: j,
                                      type: 'reschedule',
                                    })
                                  "
                                  depressed
                                  color="green"
                                  dark
                                  class="mr-2"
                                  ><v-icon class="mr-2"
                                    >mdi-calendar-arrow-left</v-icon
                                  >
                                  Reschedule</v-btn
                                >
                                <v-btn
                                  @click="startChat(item.id, sesi)"
                                  depressed
                                  :disabled="!sesi.schedule.booking_time"
                                  color="blue"
                                  dark
                                  ><v-icon class="mr-2">mdi-chat</v-icon>
                                  Chat</v-btn
                                >
                              </div>
                              <v-btn
                                v-if="sesi.schedule.is_done == 1"
                                :to="
                                  `/history-chat/${item.id}/${sesi.konsultasi_schedule_id}`
                                "
                                depressed
                                color="blue"
                                dark
                                ><v-icon class="mr-2">mdi-chat</v-icon> Riwayat
                                Chat</v-btn
                              >
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>
                    <div class="d-flex justify-space-between align-end">
                      <v-btn
                        color="#3B053C"
                        dark
                        depressed
                        :to="`/counseling/detail-transaction/${item.id}`"
                        >Transaksi Detail</v-btn
                      >
                      <div>
                        <p class="mb-0">Harga</p>
                        <b>
                          Rp{{
                            item.price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                          }}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="item.status == 0">
                  Transaksi Anda masih pending
                  <div class="d-flex justify-space-between align-end">
                    <v-btn
                      color="#3B053C"
                      dark
                      depressed
                      :to="`/counseling/detail-transaction/${item.id}`"
                      >Transaksi Detail</v-btn
                    >
                    <div>
                      <p class="mb-0">Harga</p>
                      <b>
                        Rp{{
                          item.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }}
                      </b>
                    </div>
                  </div>
                </div>
                <div v-if="item.status == 3">
                  Transaksi anda sudah kadaluarsa
                  <div class="d-flex justify-space-between align-end">
                    <v-btn
                      color="#3B053C"
                      dark
                      depressed
                      :to="`/counseling/detail-transaction/${item.id}`"
                      >Transaksi Detail</v-btn
                    >
                    <div>
                      <p class="mb-0">Harga</p>
                      <b>
                        Rp{{
                          item.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }}
                      </b>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="pa-2 d-flex justify-center">
            <v-card outlined class="pa-2 d-flex align-center">
              <v-btn
                min-width="10px"
                small
                dark
                color="#3b053c"
                @click="paginate('prev')"
                ><v-icon small>mdi-arrow-left</v-icon></v-btn
              >
              <div class="mx-3 small_txt">{{ page }} of {{ last_page }}</div>
              <v-btn
                min-width="10px"
                small
                dark
                color="#3b053c"
                @click="paginate('next')"
                ><v-icon small>mdi-arrow-right</v-icon></v-btn
              >
            </v-card>
          </div>
        </div>
      </div>
    </v-card>
    <div class="pa-3">
      <confirm-chat
        :d_chat="d_chat"
        :inventory="inventory"
        @close="d_chat = false"
      />

      <atur-jadwal
        :d_jadwal="d_jadwal"
        @close="d_jadwal = false"
        :inventory="inventory"
        @refetch="fetchData"
      />
      <v-snackbar v-model="snack" :timeout="-1"> Loading... </v-snackbar>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import firebase from "firebase";

// import StarRating from "vue-star-rating";
import AturJadwal from "./Modal/aturJadwal.vue";
import ConfirmChat from "./Modal/confirmChat.vue";
export default {
  name: "aktifseksyen",
  components: {
    // StarRating,
    AturJadwal,
    ConfirmChat,
  },
  computed: {
    ...mapState({
      ava: (state) => state.dummy,
      env: (state) => state.API_URL,
    }),
  },
  data() {
    return {
      loading: false,
      snack: false,
      d_jadwal: false,
      inventory: null,
      dateNow: this.$date().format("YYYY-MM-DD HH:MM:ss"),
      header: [
        {
          txt: "No Transaksi",
          col: 2,
        },
        {
          txt: "Sesi ke",
          col: 1,
        },
        {
          txt: "Waktu Konseling (WIB)",
          col: 3,
        },
        {
          txt: "Status",
          col: 2,
        },
        {
          txt: "",
          col: 2,
        },
        {
          txt: "Detail",
          col: 1,
        },
      ],
      trx: null,
      page: 1,
      limit: 10,
      total: null,
      last_page: null,
      listSchedule: null,
      d_chat: false,
      idSesi: null,
      d_schedule: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    startChat(id, sesi) {
      this.inventory = {
        id: id,
        sesi: sesi.konsultasi_schedule_id,
      };
      this.d_chat = true;
    },

    fetchData() {
      this.loading = true;
      let data = {
        page: this.page,
        limit: this.limit,
      };
      this.$store
        .dispatch("counseling/listTransactionCust", data)
        .then((data) => {
          data.data.forEach((el) => {
            if (el.transactions_expired == 1 && el.status == 0) {
              el["status"] = 3;
            }
          });
          // data.data.forEach((el) => {
          //   el.sessions.forEach((sesi) => {
          //     sesi.schedule.rating = parseInt(sesi.schedule.rating);
          //     sesi.schedule.rating_kualitas = parseInt(
          //       sesi.schedule.rating_kualitas,
          //     );
          //     sesi.schedule.rating_waktu = parseInt(sesi.schedule.rating_waktu);
          //   });
          // });
          this.trx = data.data;
          this.total = data.total;
          this.last_page = data.last_page;
          this.loading = false;
        });
    },

    setSchedule(item) {
      console.log(item);
      // this.snack = true;
      let start = null;
      if (item.index > 0) {
        start = this.$date(
          item.data.sessions[item.index - 1].schedule.booking_time,
        )
          .add(30, "day")
          .format("YYYY-MM-DD");
      }
      this.$store
        .dispatch("counseling/viewPsycholog", item.data.user_id)
        .then((data) => {
          this.inventory = {
            id: item.data.id,
            user_id: item.data.user_id,
            schedule: data.my_schedule,
            sesi_id: item.sesi.konsultasi_schedule_id,
            session: item.index + 1,
            max_date: start,
            type: item.type,
          };
          this.d_jadwal = true;
          this.snack = false;
        });
    },
    paginate(state) {
      console.log(state);
      if (state == "next") {
        if (this.page < this.last_page) {
          this.page++;
          this.fetchData();
        }
      } else {
        if (this.page > 1) {
          this.page--;
          this.fetchData();
        }
      }
    },
  },
};
</script>

<style></style>
