<template>
  <v-row justify="start">
    <v-dialog v-model="d_chat" persistent max-width="350px">
      <v-card class="radius-card hide_overflow" flat>
        <div class="d-flex justify-end pa-1">
          <v-btn small @click="$emit('close')" icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div>
          <v-card
            class="radius-card d-flex align-center justify-center pa-3"
            flat
            v-if="inventory"
            height="150px"
          >
            <div>
              <h3 class="mb-4 text-center">Siap Konsultasi?</h3>
              <v-btn
                @click="$emit('close')"
                class="mr-3 pa-5"
                outlined
                width="90px"
                color="#3b053c"
                dark
                >Tidak</v-btn
              >
              <v-btn
                @click="confirmed"
                class="pa-5"
                width="90px"
                outlined
                color="#3b053c"
                dark
                >Ya</v-btn
              >
            </div>
          </v-card>
        </div>
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="purple"
          ></v-progress-circular>
          <b class="purple--text">Loading...</b>
        </v-overlay>
        <v-card-actions class="pa-0"> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import firebase from "firebase";

export default {
  name: "alertQuiz",
  props: ["d_chat", "inventory"],

  data() {
    return {
      dateNow: this.$date().format("YYYY-MM-DD"),
      date: null,
      calendar: false,
      dayPicked: null,
      loading: false,
      hourPicked: null,
    };
  },

  methods: {
    // fungsi ini buat ngasih toleransi ke pelanggan 10 menit
    confirmed() {
      this.loading = true;
      // let now = "2022-08-04 14:09:00";
      let now = this.$date().format("YYYY-MM-DD HH:mm:ss");
      let finalTime = ""; //initial waktu mulai konsultasi
      let db = firebase.firestore();
      let collection = db
        .collection("konsultasi")
        .doc(`${this.inventory.sesi}`);
      collection.get().then((doc) => {
        let data = doc.data();
        console.log("booking time", data.booking_time);

        if (now >= data.booking_time) {
          //kalo sekarang itu udah lewat dari waku mulai konsulnya

          // tambah 10 menit waktu mulai
          let start_time = this.$date(data.booking_time)
            .add(10, "minutes")
            .format("YYYY-MM-DD HH:mm:ss");

          if (now >= start_time) {
            //kalo waktu sekarang udah lewat dari variable start_timer
            finalTime = start_time; // set waktu mulai konsultasi pake variable start_timer
          } else {
            //kalo waktu sekarang belum lewat dari variable start_timer
            finalTime = now; // set waktu mulai konsultasi pake waktu sekarang
          }

          console.log("wikwok", now);

          if (data.is_start == 0) {
            //kalo is_start masih 0
            collection.update({ is_start: 1 });
            collection.update({ start_time: finalTime });
          }
          this.$router.push(`/chat/${this.inventory.id}`);
        } else {
          this.$router.push(`/chat/${this.inventory.id}`);
        }
        this.loading = false;
      });
    },
  },
};
</script>
