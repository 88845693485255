<template>
  <v-row justify="start">
    <v-dialog v-model="d_jadwal" persistent max-width="450px">
      <v-card class="radius-card hide_overflow" flat>
        <div class="d-flex justify-end pa-3">
          <v-btn small @click="$emit('close')" icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div class="pa-3">
          <v-card
            color="#eae8e8"
            class="radius-card pa-3"
            min-height="200px"
            flat
            v-if="inventory"
          >
            <v-menu
              v-model="calendar"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  class="pa-2 rounded d-flex justify-space-between align-center"
                >
                  <p class="ma-0 small_txt">
                    {{ date ? date : "Pilih Tanggal" }}
                  </p>
                  <v-icon>mdi-calendar</v-icon>
                </v-card>
              </template>
              <v-date-picker
                v-model="date"
                :max="inventory.max_date"
                :min="dateNow"
                @input="parseSchedule()"
              ></v-date-picker>
            </v-menu>

            <v-card class="pa-3 rounded mt-3" flat v-if="dayPicked">
              <v-btn-toggle
                v-if="dayPicked.time.length"
                flat
                v-model="hourPicked"
                active-class="active_time"
                borderless
              >
                <div class="d-flex flex-wrap">
                  <v-btn
                    class="ma-2"
                    depressed
                    v-for="(item, idx) in dayPicked.time"
                    :key="idx"
                    :value="item"
                  >
                    {{ item }}
                  </v-btn>
                </div>
              </v-btn-toggle>
              <div class="pa-3" v-else>
                <h3>
                  Tidak tersedia di tanggal ini, silakan pilih tanggal lain.
                </h3>
              </div>
            </v-card>
          </v-card>
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="purple"
            ></v-progress-circular>
            <b class="purple--text">Loading...</b>
          </v-overlay>
        </div>
        <v-card-actions class="py-3">
          <v-btn
            v-if="hourPicked"
            color="#3b053c"
            dark
            depressed
            @click="setJadwal"
            >Pesan Sekarang</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "aturJadwal",
  props: ["d_jadwal", "inventory"],

  data() {
    return {
      dateNow: this.$date().format("YYYY-MM-DD"),
      date: null,
      calendar: false,
      dayPicked: null,
      loading: false,
      hourPicked: null
    };
  },

  methods: {
    parseSchedule() {
      this.calendar = false;
      let day = "";
      switch (this.$date(this.date).format("dd")) {
        case "Su":
          day = "Minggu";
          break;
        case "Mo":
          day = "Senin";
          break;
        case "Tu":
          day = "Selasa";
          break;
        case "We":
          day = "Rabu";
          break;
        case "Th":
          day = "Kamis";
          break;
        case "Fr":
          day = "Jumat";
          break;
        case "Sa":
          day = "Sabtu";
          break;
      }
      let sameDay = null;
      sameDay = this.inventory.schedule.find(el => el.day == day);
      if (sameDay) {
        this.dayPicked = sameDay;
      } else {
        this.dayPicked = {
          time: []
        };
      }

      // this.inventory.schedule.forEach(el => {
      //   if (el.day == day) {
      //     this.dayPicked = el;
      //   }
      // });
      let hour = this.$date().format("YYYY-MM-DD HH:mm");
      let remainhour = [];
      if (this.dateNow == this.date) {
        this.dayPicked.time.forEach(el => {
          let date1 = `${this.date} ${el}`;
          if (date1 > hour) {
            remainhour.push(el);
          }
        });
        this.dayPicked["time"] = remainhour;
      }
    },
    setJadwal() {
      const vm = this;
      vm.loading = true;
      let body = {
        user_id: vm.inventory.user_id,
        booking_time: `${vm.date} ${vm.hourPicked}`
      };
      let path = "";
      if (this.inventory.type == "set") {
        body["id"] = this.inventory.id;
        body["session"] = this.inventory.session;
        path = `public/konsultasi/booking/next-session`;
      } else {
        body["konsultasi_schedule_id"] = this.inventory.sesi_id;
        path = `public/konsultasi/reschedule`;
      }
      let data = {
        path: path,
        body: body
      };
      vm.$store
        .dispatch("postApi", data)
        .then(data => {
          vm.$store.dispatch("notify", {
            msg: data.message,
            type: "success"
          });
          vm.$emit("refetch");
          vm.$emit("close");
          vm.loading = false;
        })
        .catch(e => {
          vm.loading = false;
          vm.$store.dispatch("notify", {
            msg: e.message,
            type: "error"
          });
        });
    }
  }
};
</script>

<style scoped>
.active_time {
  background: #3b053c !important;
  color: #fff !important;
}
</style>
